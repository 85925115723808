import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseInvoiceStatementList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'statement_no', label: '结算单号' , sortable: true},
    { key: 'order_no', label: '采购订单号' },
    { key: 'company_name', label: '采购主体' },
    { key: 'supplier_name', label: '供应商名称' },
    { key: 'amount', label: '结算单金额' },
    { key: 'apply_pay', label: '本次申请付款金额' },
    { key: 'payed_pay', label: '已付金额' },
    { key: 'nopayed_pay', label: '未付金额' },
    { key: 'last_pay_date', label: '最晚付款时间' },
    { key: 'create_time', label: '创建时间' },
    { key: 'creator', label: '创建人' },
    { key: 'status_one', label: '状态' },
    { key: 'actions', label: '操作'},
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" statement_id desc ")
  const isSortDirDesc = ref(false)
  const tabStatus = ref(1)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit,tabStatus], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    console.log("mmmmmmmmmmmmmm")
    const condition = {...store.getters['purchaseInvoiceStatementList/getCondition']}
    store
        .dispatch('purchaseInvoiceStatementList/search', {
          company_name: condition.company_name,
          supplier_name: condition.supplier_name,
          status_one: tabStatus.value,
          create_date_str: condition.create_date_str,
          statement_no: condition.statement_no,
          push_status: condition.push_status,
          //推送状态

          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const list = response.data.data.ext.list
          listTotals.value = response.data.data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '应付结算列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    tabStatus,

  }
}
