<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="openAddPage"
                  class="ml-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-right">新增</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal">
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="采购方主体:"
                    label-for="company_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="company_name"
                      v-model="condition.company_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="供应商名称:"
                    label-for="supplier_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="supplier_name"
                      v-model="condition.supplier_name"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="推送状态:"
                    label-for="push_status"
                    label-size="sm"
                >
                  <v-select
                      id="push_status"
                      :options="getCodeOptions('push_status')"
                      :clearable="true"
                      v-model = "condition.push_status"
                      class="select-size-sm"
                      placeholder="选择推送状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" cols="12">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['create_date_str'])"
                                 :params="['create_date_str']"
                                 :value="condition.create_date_str"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="结算单号:"
                    label-for="statement_no"
                    label-size="sm"
                >
                  <b-form-input
                      id="statement_no"
                      v-model="condition.statement_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </div>

      <xy-tab
          :tabs="getCodeOptions('purchase_invoice_statement_status')"
          :statusValue="status_one"
          v-on:emitTabs="function($event) {tabStatus = $event}"
      />

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="invoice_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >

        <template #cell(statement_no)="data">
          {{ data.item.statement_no }}
        </template>
        <template #cell(order_no)="data">
          <div :id="`memo-${data.item.statement_id}`">
            <span v-if="data.item.ext.orderNos">
              {{ data.item.ext.orderNos.substring(0, 14) }}
              <span v-if="data.item.ext.orderNos.length>14">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo-${data.item.statement_id}`" placement="top">
            {{ data.item.ext.orderNos }}
          </b-tooltip>
        </template>
        <template #cell(company_name)="data">
          {{ data.item.company_name }}
        </template>
        <template #cell(supplier_name)="data">
          {{ data.item.supplier_name }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount }}
        </template>
        <template #cell(apply_pay)="data">
          {{ data.item.apply_pay }}
        </template>
        <template #cell(payed_pay)="data">
          {{ data.item.payed_pay }}
        </template>
        <template #cell(nopayed_pay)="data">
          {{ data.item.nopayed_pay }}
        </template>
        <template #cell(last_pay_date)="data">
          {{ toDate(data.item.last_pay_date) }}
        </template>
        <template #cell(create_time)="data">
          {{ toDate(data.item.create_time) }}
        </template>
        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>
        <template #cell(status_one)="data">
          {{ getCodeLabel('purchase_invoice_statement_status', data.item.status_one) }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
<!--            <b-dropdown-item v-if="data.item.status_one == 1" @click="submitData(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交</span>
            </b-dropdown-item>-->
            <b-dropdown-item v-if="data.item.status_one == 1 ||data.item.status_one == 6 ||data.item.status_one == 7" @click="cancelData(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">取消</span>
            </b-dropdown-item>
            <b-dropdown-item @click="viewData(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">查看</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="data.item.status_one == 3 ||data.item.status_one == 4 ||data.item.status_one == 8||data.item.status_one == 9" @click="printData(data.item)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">打印封面</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <!--/ form -->
      <b-modal
          id="loadingModal"
          centered
          hide-header
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          ref="loadingModal"
      >
        <p class="my-4">正在处理，请勿关闭浏览器!</p>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem,BFormRadio, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import XyTab from "@/views/components/xy/XyTab";
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import { toDate,avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,isEmpty,lodopWatermark } from '@core/utils/filter'
import purchaseInvoiceStatementListStore from "@/views/apps/purchaseinvoicestatement/purchaseInvoiceStatementListStore";
import purchaseInvoiceStatementList from './purchaseInvoiceStatementList.js'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {second} from "@core/utils/dayjs/utils";
import {useToast} from "vue-toastification/composition";
import {getLodop} from "@/libs/LodopFuncs";

export default {
  components: {
    BCard,
    BModal,
    XyTab,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormRadio,
    BPagination,
    vSelect,
    XyInputButton,
    AttachmentUpload,
    // purchaseInvoiceEdit,
  },
  data() {
    return {
      advanced_search_modal: false,
      readonlyFlag:false,
      attachmentsStr: "",
      status_one:1,
    }
  },
  methods: {
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {status_one:'1'}
      store.commit('purchaseInvoiceStatementList/updateCondition', this.state.condition)
      this.refetchData()
    },
    //提交
    submitData(item){
      this.$refs['loadingModal'].show()
      store.dispatch('purchaseInvoiceStatementList/changeStatus', {id:item.statement_id,status_one:2}).then(res => {
        this.$refs['loadingModal'].hide()
        if (res.data.code==0){
          this.refetchData()
        }else {
          alert(res.data.data);
        }
      })
    },
    //取消
    cancelData(item){
      this.$swal({
        title: "确定取消吗",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$refs['loadingModal'].show()
          store
              .dispatch('purchaseInvoiceStatementList/changeStatus', {
                id:item.statement_id,
                status_one:5
              })
              .then(res => {
                this.$refs['loadingModal'].hide()
                if (res.data.code === 0) {
                  //弹窗
                  this.$swal({
                    icon: 'success',
                    title: '已取消!',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.refetchData()
                } else {
                  alert(res.data.data)
                }
              })
        }
      })
    },
    //查看
    viewData(item){
      this.$router.push({name: 'apps-purchase-invoice-statement-edit', query: { id: item.statement_id }});
    },
    //打印
    printData(item){
      store.dispatch('purchaseInvoiceStatementList/printData', {id:item.statement_id}).then(res => {
        let model = res.data.data;
        console.log("fffffffffffff")
        console.log(res.data.data)
        let invoiceNos = res.data.data.ext.invoiceNos;
        let totalInvoice = res.data.data.ext.totalInvoice;
        let totalInvoiceTaxAmount = res.data.data.ext.totalInvoiceTaxAmount;
        let totalApplyAmount = res.data.data.ext.totalApplyAmount;
        let totalVerifyAmount = res.data.data.ext.totalVerifyAmount;
        let totalInvoiceCostTaxSubtotal = res.data.data.ext.totalInvoiceCostTaxSubtotal;
        let invoiceType = res.data.data.ext.invoiceType;
        let orderList = res.data.data.ext.orderList;
        let inboundOutboundList = res.data.data.ext.inboundOutboundList;
        let invoiceList = res.data.data.ext.invoiceList;
        var LODOP = getLodop();
        if (LODOP == undefined) {
          toast.error("打印插件未安装，请安装插件!")
          return;
        }
        LODOP.PRINT_INIT('应付结算单') //打印初始化
        //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
        LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
        // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
        LODOP.SET_PRINT_PAGESIZE(1, 0, 0, 'A4') //设定纸张大小
        // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
        LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
        let items1 = ``;
        for (let i = 0; i < orderList.length; i++) {
          items1 += `<tr>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].order_no}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${getCodeLabel('business_type', orderList[i].type_id)}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">非项目类</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${getCodeLabel('agreement_pay_method', orderList[i].payment_method)}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].purchase_total}</td>
          </tr>`
        }
        let items2 = ``;
        for (let i = 0; i < inboundOutboundList.length; i++) {
          items2 += `<tr>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${inboundOutboundList[i].order_no}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${inboundOutboundList[i].bound_no}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${toDate(inboundOutboundList[i].bound_add_time)}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${inboundOutboundList[i].status_str}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${inboundOutboundList[i].cost_tax_subtotal}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${inboundOutboundList[i].cost_subtotal}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${inboundOutboundList[i].tax_subtotal}</td>
          </tr>`
        }
        let items3 = ``;
        for (let i = 0; i < orderList.length; i++) {
          items3 += `<tr>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].order_no}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].reward_amount==undefined?'':orderList[i].reward_amount}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].refund_amount==undefined?'':orderList[i].refund_amount}</td>
          </tr>`
        }
        let items4 = ``;
        for (let i = 0; i < invoiceList.length; i++) {
          items4 += `<tr>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${getCodeLabel('invoice_type', invoiceList[i].invoice_type)}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].invoice_code}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].invoice_no}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].buyer_name}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].sell_name}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].amount}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].tax_amount}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].total}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.currency}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${toDate(invoiceList[i].make_date_val)}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].sell_idno}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${invoiceList[i].sell_bankinfo}</td>
          </tr>`
        }
        let items5 = ``;
        for (let i = 0; i < orderList.length; i++) {
          items5 += `<tr>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].order_no}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].payable_amount}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].verify_amount}</td>
          <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${orderList[i].apply_amount}</td>
          </tr>`
        }
        let html = `<div style="background-color: #e0dddd;width: 100%;">
                    <img src="${model.ext.src}" width="150" height="80" style="float: right;margin-right: 5px;margin-top: 6px">
                    <H3 style="margin-left: 10px">结算单号：${model.statement_no}</H3>
                    <span style="margin-left: 10px">创建人:${getCodeLabel('user', model.creator)}</span><br><br>
                    <span style="margin-left: 10px">创建时间:${toDate(model.create_time)}</span>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <p style="background-color: #e0dddd;margin: 0">采购单信息</p>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <table style="width: 100%"><tr><td>
                            <table style="width:90%;margin-left: 20px;margin-top:20px"><thead><tr>
                                    <td>供应商名称：${model.supplier_name}</td>
                                    <td>采购主体：${model.company_name}</td>
                                    <td>合同编号：${model.contract_no}</td>
                                    <td>结算币种：${model.currency}</td>
                                    <td>发票类型：${getCodeLabel('invoice_type', invoiceType)}</td>
                                </tr></thead></table>
                            <table style="margin-top: 10px;width: 80%;border-collapse: collapse ;margin-left: 20px;margin-bottom: 20px">
                                        <thead>
                                        <tr>
                                            <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">采购订单号</td>
                                            <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">业务类型</td>
                                            <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">项目标签</td>
                                            <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">付款方式</td>
                                            <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">订单总金额(原币)</td>
                                        </tr>
                                        </thead>
                                        <tbody>
            ${items1}
                                        </tbody>
                                    </table>
                        </td></tr></table>
                    </td></tr></tbody></table>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                            <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                            <p style="background-color: #e0dddd;margin: 0">采购入库单/采退出库信息</p>
                            <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                            <table style="margin-top: 10px;margin-bottom:20px;width: 80%;border-collapse: collapse ;margin-left: 20px;margin-top: 20px;border-right: 1px solid">
                                <thead><tr>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">采购订单号</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">采购入库单</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">创建时间</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">状态</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">实际入库含税总额(原币)</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">实际入库不含税总额(原币)</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">实际入库税额(原币)</td>
                                </tr></thead>
                                <tbody>
                                    ${items2}
                                </tbody>
                            </table>
                        </td></tr></tbody>
                    </table>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <p style="background-color: #e0dddd;margin: 0">抵扣信息</p>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <div style="margin-top: 20px;margin-bottom: 20px">
                            <table style="margin-top: 10px;margin-bottom:20px;width: 50%;border-collapse: collapse ;margin-left: 20px">
                                <thead><tr>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">采购订单号</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">返利抵扣金额(原币)</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">退款抵扣金额(原币)</td>
                                </tr></thead>
                                <tbody>${items3}
                                </tbody>
                            </table>
                        </div>
                    </td></tr></tbody></table>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <p style="background-color: #e0dddd;margin: 0">发票信息</p>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <div style="margin-top: 20px;margin-bottom: 20px;">
                            <table style="width:80%;margin-left: 20px">
                                <thead><tr>
                                    <td>发票号码：${invoiceNos}</td>
                                    <td>发票金额：${totalInvoice}${model.currency}</td>
                                    <td>税额：${totalInvoiceTaxAmount}${model.currency}</td>
                                </tr></thead>
                            </table>
                            <table style="margin-top: 10px;margin-bottom: 20px;width: 95%;border-collapse: collapse ;margin-left: 20px">
                                <thead><tr>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">发票类型</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">发票代码</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">发票号码</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">购买方名称</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">销售方名称</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">金额</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">税额</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">价税合计</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">币种</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">开票日期</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">收款人纳税识别号</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">收款开户行及账号</td>
                                </tr></thead>
                                <tbody>${items4}</tbody>
                            </table>
                        </div>
                    </td></tr></tbody></table>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <p style="background-color: #e0dddd;margin: 0">差异信息</p>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <div style="margin-top: 20px;margin-bottom: 20px;">
                            <table style="width:80%;margin-left: 20px;margin-bottom:20px;"><thead> <tr>
                                    <td>税额差异金额（原币）：${model.tax_amount_difference} ${model.currency}<br><span style="font-size: 15px;color: #b4b7bd">税额差异金额=入库单税额-发票税额</span></td>
                                    <td>价税合计差异金额（原币）：${model.total_amount_difference}  ${model.currency}<br><span style="font-size: 15px;color: #b4b7bd">价税合计差异金额=入库单价税合计金额-发票价税合计金额</span></td>
                                    <td colspan="2">差异原因：${model.memo_difference==undefined?'':model.memo_difference}</td>
                            </tr></thead></table>
                        </div>
                    </td></tr></tbody></table>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <p style="background-color: #e0dddd;margin: 0">付款信息</p>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <div style="margin-top: 20px;margin-bottom: 20px">
                            <table style="width:90%;margin-left: 20px"><thead><tr>
                                <td>结算单金额（原币）：${model.amount}  ${model.currency}</td>
                                <td>本次核销预付款（原币）：${model.verify_pre_pay} ${model.currency}</td>
                                <td>本次申请付款金额（原币）：${model.apply_pay} ${model.currency}</td>
                            </tr></thead></table>
                            <table style="margin-top: 10px;width: 80%;border-collapse: collapse ;margin-left: 20px">
                                <thead><tr>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">采购订单号</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">本次应付金额（原币）</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">本次核销预付款（原币）</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">本次申请付款金额（原币）</td>
                                </tr></thead>
                                <tbody>${items5}</tbody>
                            </table>
                            <table style="width:80%;margin-left: 20px;margin-top: 20px;margin-bottom: 10px;">
                                <thead><tr>
                                    <td>最晚付款日期：${toDate(model.last_pay_date)}</td>
                                    <td>付款币种：${model.currency}</td>
                                    <td colspan="4">付款事由：${model.pay_reason==undefined?'':model.pay_reason}</td>
                                </tr></thead>
                            </table>
                        </div>
                    </td></tr></tbody></table>
                </div>
                <div style="margin-top: 10px;width: 100%;">
                    <table style="width: 100%;border-left: 1px solid;border-bottom: 1px solid"><tbody><tr><td>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <p style="background-color: #e0dddd;margin: 0">收款方信息</p>
                        <p style="background-color: #e0dddd;margin: 0">&nbsp;</p>
                        <div style="margin-top: 20px;margin-bottom: 20px">
                            <table style="width:60%;margin-left: 20px"><thead><tr>
                                <td>供应商名称：${model.supplier_name} </td>
                                <td>收款账户是否与合同一致：${getCodeLabel('yesno', model.is_same)}</td>
                            </tr></thead></table>
                            <table style="margin-top: 10px;margin-bottom:20px;width: 80%;border-collapse: collapse ;margin-left: 20px">
                                <thead><tr>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">收款人类型</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">收款人户名</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">收款人账号</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">收款行及支行名称</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">银行地址</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">是否境外收款方</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">swift code</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">备注</td>
                                </tr></thead>
                               <tbody><tr>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${getCodeLabel('bank_account_type', model.account_type)}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.account_name==undefined?'':model.account_name}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.account_no==undefined?'':model.account_no}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.bank_name==undefined?'':model.bank_name}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.bank_node_name==undefined?'':model.bank_node_name}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${getCodeLabel('yesno', model.is_outlands)}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.swift_code==undefined?'':model.swift_code}</td>
                                    <td style="text-align: center;border: 1px solid #000;border-collapse: collapse">${model.account_remark==undefined?'':model.account_remark}</td>
                                </tr></tbody>
                            </table>
                        </div>
                    </td></tr></tbody></table>
                </div>`
        lodopWatermark(LODOP)
        LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500,  '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
        // LODOP.ADD_PRINT_IMAGE(10,550,200,100,"<img src='D:\\upload\\statement\\1671506553654-PODZ22121917402376079688.png'/>");
        LODOP.NewPage();
        LODOP.PREVIEW()
      })
    },
    //新增
    openAddPage: function () {
      this.$router.push({name: 'apps-purchase-invoice-statement-edit', query: { id: 0 }});
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    const toast = useToast()

    const state = reactive({
      condition: {},
    })
    // Register module
    if (!store.hasModule('purchaseInvoiceStatementList')) store.registerModule('purchaseInvoiceStatementList', purchaseInvoiceStatementListStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseInvoiceStatementList')) store.unregisterModule('purchaseInvoiceStatementList')
    })
    onMounted(() => {
      state.condition = store.getters['purchaseInvoiceStatementList/getCondition']
    })

    const advanced_search = function () {
      this.advanced_search_modal = !this.advanced_search_modal;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const initPage = function (){
    }

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,


    } = purchaseInvoiceStatementList(
        {}
    )

    return {
      ...toRefs(state),
      state,
      searchList,
      initPage,
      fromChildren,
      advanced_search,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      tabStatus,

      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      toDate,
      getCodeColor,
      getCodeOptions,
      isEmpty,
    }
  },
  created() {
    this.initPage()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
